@import url("../foundations/normalize.css");
@import url("../foundations/fonts.css");
@import url("../foundations/typography.css");
@import url("../foundations/colors.css");
@import url("../foundations/spacings.css");
@import url("../foundations/radius.css");
@import url("../foundations/ratios.css");
@import url("../foundations/grids.css");
@import url("../foundations/devices.css");

@import url("../elements/avatars.css");
@import url("../elements/buttons.css");
@import url("../elements/icons.css");
@import url("../elements/images.css");
@import url("../elements/logos.css");
@import url("../elements/tags.css");
@import url("../elements/youtubevideo.css");

@import url("../modules/header.css");
@import url("../modules/footer.css");
@import url("../modules/horizontaltextboxes.css");
@import url("../modules/usecases.css");
@import url("../modules/mediaimages.css");
@import url("../modules/blockquote.css");
@import url("../modules/imagebar.css");
@import url("../modules/imagesimple.css");
@import url("../modules/listgroupstack.css");
@import url("../modules/jumbo.css");
@import url("../modules/featuredlist.css");
@import url("../modules/textfeatured.css");
@import url("../modules/textsimple.css");
@import url("../modules/cardstack.css");
@import url("../modules/logolist.css");
@import url("../modules/faq.css");
@import url("../modules/accordion.css");
@import url("../modules/media.css");
@import url("../modules/listgroup.css");
@import url("../modules/markdown.css");
@import url("../modules/medialogos.css");
@import url("../modules/videosimple.css");
@import url("../modules/mediavideo.css");
@import url("../modules/herovideo.css");
@import url("../modules/hero.css");
@import url("../modules/heroiframe.css");
@import url("../modules/heroillustration.css");
@import url("../modules/sourcelist.css");
@import url("../modules/success.css");
@import url("../modules/dialog.css");
@import url("../modules/secondarymenu.css");

:root,
.m-jumbo.-brand {
  --color-raw-dark: #372D5E;
  --color-raw-medium: #F3EDFF;
  --color-raw-light: #FAF8FF;
  --color-raw-white: white;

  --color-accent: #B696FF;

  --color-state-primary-active: var(--color-accent);
  --color-state-primary-hover: #B696FFaa;
  --color-state-secondary-active: var(--color-raw-dark);
  --color-state-secondary-hover: var(--color-accent);
  --color-state-tertiary-active: transparent;
  --color-state-tertiary-hover: transparent;

  --color-border-primary: var(--color-accent);
  --color-border-secondary: transparent;
  --color-border-tertiary: var(--color-state-primary-hover);

  --color-background-base: var(--color-raw-light);
  --color-background-subdued: var(--color-raw-light);
  --color-background-surface: var(--color-raw-white);

  --color-action-primary: var(--color-accent);
  --color-action-secondary: var(--color-raw-medium);

  --color-bodytext-primary-action: var(--color-raw-dark);
  --color-bodytext-secondary-action: var(--color-raw-dark);
  --color-bodytext-tertiary-action: var(--color-raw-dark);
  --color-bodytext-primary: var(--color-raw-dark);
  --color-bodytext-secondary: var(--color-raw-dark);

  --color-surface: var(--color-raw-medium);
}

.m-jumbo.-brand {
  --color-action-secondary: var(--color-raw-white);
}

.-brand {
  --color-accent: var(--color-raw-green);

  --color-state-primary-active: transparent;
  --color-state-primary-hover: transparent;
  --color-state-secondary-active: transparent;
  --color-state-secondary-hover: transparent;
  --color-state-tertiary-active: transparent;
  --color-state-tertiary-hover: transparent;

  --color-border-primary: var(--color-raw-green);
  --color-border-secondary: transparent;
  --color-border-tertiary: transparent;

  --color-background-base: var(--color-raw-dark);
  --color-background-subdued: transparent;

  --color-action-primary: var(--color-raw-green);
  --color-action-secondary: var(--color-raw-green);

  --color-bodytext-primary-action: var(--color-raw-dark);
  --color-bodytext-secondary-action: var(--color-raw-cream);
  --color-bodytext-tertiary-action: var(--color-raw-cream);
  --color-bodytext-primary: var(--color-raw-green);
  --color-bodytext-secondary: var(--color-raw-cream);

  --color-surface: var(--color-raw-dark);
}

@font-face {
  font-family: PermanentMarker;
  font-style: normal;
  font-weight: 600;
  src: url(/static/fonts/PermanentMarker-Regular.woff);
  ascent-override: 124%;
}

@font-face {
  font-family: InterMedium;
  font-style: normal;
  font-weight: 400;
  src: url(/static/fonts/Inter-Medium.woff);
  ascent-override: 100%;
}

@font-face {
  font-family: InterBold;
  font-style: bold;
  font-weight: 800;
  src: url(/static/fonts/Inter-Bold.woff);
  ascent-override: 100%;
}

strong {
  font-family: InterBold;
}

.t-title-lead {
  font: 600 var(--typography-title-lead) PermanentMarket;
}

.t-title-1 {
  font: 600 var(--typography-title-1) PermanentMarker;
}

.t-title-2 {
  font: 600 var(--typography-title-2) PermanentMarker;
}

.t-title-3 {
  font: 600 var(--typography-title-3) PermanentMarker;
}

.t-subtitle-lead {
  font: 600 var(--typography-subtitle-lead) PermanentMarker;
}

.t-subtitle {
  font: var(--typography-subtitle) InterMedium;
}

.t-text-superlead {
  font: var(--typography-text-superlead) InterMedium;
}

.t-cta {
  font: var(--typography-cta) InterBold;
}

.t-text-lead {
  font: var(--typography-text-lead) InterMedium;
}

.t-text {
  font: var(--typography-text) InterMedium;
}

.t-text-small {
  font: var(--typography-text-small) InterMedium;
}

.t-overline {
  font: var(--typography-overline) InterMedium;
}

.m-header {
  background-color: var(--color-background-base);
}

.m-header>.g-container {
  align-items: center;
}

.m-footer .g-container {
  align-items: center;
}

.m-header .logo,
.m-footer .logo {
  flex-grow: 1;
}

.m-header nav.g-cell.-x9,
.m-footer .g-cell.-x9 {
  width: auto;
}

.m-header nav.-open {
  display: flex;
  opacity: 1;
}

.m-header #-menu-mobile {
  display: none;
  opacity: 0;
}

.m-header .logo img,
.m-footer .logo img {
  height: 32px;
  width: auto;
  display: block;
}

.m-header .e-icon.-direction_up_md {
  background-color: var(--color-bodytext-primary);
}

.m-header ul,
.m-footer ul {
  list-style-type: none;
}

.m-header li,
.m-header .logo a {
  padding-bottom: var(--spacing-small);
  padding-top: var(--spacing-small);
}



.m-header ul li::before,
.m-footer ul li::before {
  display: none;
}

.m-header ul li,
.m-footer ul li {
  display: inline-block;
  margin-right: var(--spacing-xxsmall);
}

.m-header ul li:last-child a,
.m-footer ul li:last-child a {
  margin-right: 0;
}

.m-header ul li a {
  color: var(--color-bodytext-primary-action);
  text-decoration: none;
  font: var(--typography-text-small) InterMedium;
}

.m-footer ul li a {
  color: var(--color-bodytext-secondary);
  text-decoration: none !important;
}

.m-header ul,
.m-header ul li,
.m-footer ul,
.m-footer ul li {
  margin: 0;
}

.m-header a,
.m-footer a {
  padding-top: 16px;
  padding-bottom: 16px;
  margin-right: 32px;
  display: block;
  font: var(--typography-text-lead) InterMedium;
  cursor: pointer;
  position: relative;
    text-decoration: none;

}

.e-tag {
  color: var(--color-bodytext-primary);
  text-transform: uppercase;
  letter-spacing: 1px;
  font: var(--typography-overline) InterBold;
}

.e-cta {
  border-radius: 12px;
  font: var(--typography-cta) InterBold;
}

.m-header a.-active,
.m-footer a.-active {
  font: var(--typography-text-lead) InterBold;
}

.m-header .mobile {
  display: none;
}

.m-header .desktop {
  margin-right: 0;
  padding: 0;
}

.m-header li a {
  padding: 0;
}

.m-header .active,
.m-header .active > a,
.m-header .active > span,
.m-header :hover > span,
.m-header :hover > a {
  background-color: inherit;
}

.m-header li:hover {
  cursor: default;
}

.m-footer a::before,
.m-header a::before {
  content: "";
  display: inline-block;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 10px;
  background-image: url('/static/sites/fundacion/link_decoration_underline_short.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 100% 12px;
  transition: all 0.25s ease-out;
}

.m-header .logo a {
  display: inline-block;
}

.m-footer li:last-child a::before {
  background-image: url('/static/sites/fundacion/link_decoration_underline_large.svg');
}

.m-header .logo a::before {
  display: none;
}

.m-header a::before {
  bottom: -8px;
}

.m-footer a::before {
  bottom: 6px;
}

.m-footer a:hover::before {
  bottom: 4px;
  opacity: 1;
}

.m-header a:hover::before {
  bottom: -12px;
  opacity: 1;
}

.m-markdown a,
.m-markdown a:focus,
.m-markdown a:active {
  color: var(--color-bodytext-primary-action);
  text-decoration: underline;
  text-decoration-color: var(--color-accent);
  transition: all 0.25s ease-out;
}

.m-markdown a:hover {
  color: var(--color-accent);
}

.m-markdown hr {
  margin-top: var(--spacing-medium);
  border-color: var(--color-background-base);
  box-shadow: none;
}

.m-markdown strong {
  font-family: InterBold;
}

.m-horizontaltextboxes li .image {
  margin-bottom: var(--spacing-small);
}

.m-header + .m-heroillustration {
  padding-top: var(--spacing-large);
}

.m-heroillustration .t-title-1 {
  font: 600 var(--typography-title-lead) PermanentMarker;
  margin-bottom: 0;
}

.m-heroillustration .t-subtitle {
  margin-top: var(--spacing-medium);
}

.m-heroillustration .e-image.-ratio-3-1 {
  aspect-ratio: auto;
  width: 100%;
}

.m-heroillustration {
  padding-bottom: 0;
}

.m-heroillustration header {
  margin-bottom: var(--spacing-xlarge);
}

.m-heroillustration + .m-imagesimple {
  padding-top: 0;
  padding-bottom: var(--spacing-large);
}

.m-imagesimple {
  padding-bottom: var(--spacing-xlarge);
}

.m-imagesimple + .m-imagebar {
  padding-top: 0;
}

.m-imagesimple .e-image {
  border-radius: 0;
}

.m-imagebar .e-image {
  height: 100%;
  aspect-ratio: auto;
}

.m-imagebar + .m-textfeatured {
  padding-top: var(--spacing-large);
}

.m-imagebar .g-cell.-x8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.m-imagebar .t-text-superlead,
.m-imagebar .t-text-superlead p {
  margin-bottom: 0;
}

.m-imagebar .t-subtitle-lead {
  margin-bottom: var(--spacing-small);
}

.m-imagesimple .g-container {
  width: 100%;
  position: relative;
}

.m-jumbo {
  padding-top: var(--spacing-xlarge);
}

.m-jumbo > section {
  padding: var(--spacing-large) var(--spacing-xlarge);
}

#proyectos>.g-container,
#contacto>.g-container,
#compromiso>.g-container {
  position: relative;
}

#proyectos>.g-container::before,
#contacto>.g-container::before,
#compromiso>.g-container::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  color: var(--color-raw-dark);
}

#contacto>.g-container::before {
  top: -50px;
  left: 100px;
  content: url("/static/sites/fundacion/contacto-illustration.svg");
}

#compromiso>.g-container::before {
  right: 48px;
  bottom: -50px;
  content: url("/static/sites/fundacion/compromiso-illustration.svg");
}

#proyectos>.g-container::before {
  content: "IM PAC TO";
  font: 600 var(--typography-title-1) PermanentMarker;
  font-size: 172px;
  line-height: .80;
  top: 0;
  left: 0;
  transform: translateY(-580px);
  color: var(--color-bodytext-primary-action);
}

.m-textfeatured .t-title-3 {
  font: 600 var(--typography-title-1) PermanentMarker;
}

.m-textfeatured .t-subtitle {
  font: var(--typography-subtitle) InterMedium;
}

.m-textfeatured {
  padding-bottom: 0;
}

.m-textfeatured+* {
  padding-top: var(--spacing-small);
}

#title-quote .-x6:first-child {
  width: 100%;
}

.m-blockquote blockquote {
  background-color: var(--color-raw-green);
  border: none;
}

.m-blockquote .t-subtitle {
  font-family: PermanentMarker;
}

.m-blockquote .-list {
  overflow: visible;
}

.e-avatar.-active {
  box-shadow: 0 0 0 4px var(--color-accent);
}

.m-horizontaltextboxes.-group + .m-horizontaltextboxes.-group {
  padding-top: var(--spacing-medium);
}

.m-horizontaltextboxes + .m-textfeatured {
  padding-top: 0;
}

.m-horizontaltextboxes .content .t-text-lead,
.m-horizontaltextboxes .content .t-text-superlead {
  padding-right: var(--spacing-xsmall);
}

.m-horizontaltextboxes .t-text-superlead {
  font: var(--typography-subtitle) InterBold;
  margin-bottom: var(--spacing-xxsmall);
}

.m-horizontaltextboxes .t-text-lead p {
  margin-bottom: 0;
}

.m-horizontaltextboxes ul li.g-cell.-bordered {
  /* border-image: url(/static/sites/fundacion/bordered.svg) 16 / 32px stretch;*/
  background-color: var(--color-raw-white);
  border: none;
}

.m-blockquote section .g-container>p {
  background: transparent;
  padding: 0;
  text-transform: uppercase;
  color: #A29F89;
}

.m-blockquote h2 {
  font: 600 var(--typography-subtitle) PermanentMarker;
}

body {
  font: var(--typography-text) InterMedium;
  color: var(--color-text-primary);
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background-base);
}

body>section,
body>c-blockquote {
  padding-top: var(--spacing-xlarge);
  padding-bottom: var(--spacing-xlarge);
  display: block;
  background-color: var(--color-background-base);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 var(--spacing-medium);
  text-wrap: balance;
  color: var(--color-bodytext-primary);
}

p {
  margin: 0 0 var(--spacing-xxsmall);
  color: var(--color-bodytext-secondary);
}

ul {
  padding-left: 0;
  overflow: hidden;
  list-style-type: none;
}

ul p {
  display: inline-block;
}

ul li::before {
  content: "— ";
  color: var(--color-bodytext-secondary);
  margin-right: var(--spacing-xxsmall);
}

ul li:last-child p {
  margin-bottom: 0px;
}

a {
  color: var(--color-action-primary);
  font-weight: 600;
}

blockquote,
figure {
  margin: 0;
  padding: 0;
}

.m-horizontaltextboxes ul {
  overflow: visible;
}

.m-horizontaltextboxes ul li:not(.-bordered) img,
.m-horizontaltextboxes ul li,
.m-mediaimage > *,
.m-blockquote > *
 {
  border-radius: 40px !important;
}



.m-horizontaltextboxes ul li.g-cell.-bordered,
.m-imagebar .g-cell.-x7,
.m-blockquote blockquote {
  box-shadow: 0 20px 20px 0 #37235e0a;
  background-color: var(--color-raw-white);
}

.m-horizontaltextboxes li.g-cell.-bordered .image img.-XL {
  width: 112px;
}

.mobile [data-item='menu'] {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0 1px var(--color-border-tertiary);
  border-radius: var(--radius-small);
}

.mobile [data-item='menu'] li {
  top: 0;
  padding-bottom: 0;
  padding-top: 0;
  padding-left: var(--spacing-small);
  padding-right: var(--spacing-small);
}

.mobile [data-item='menu'] li a {
  padding-top: var(--spacing-small);
  padding-bottom: var(--spacing-small);
  padding-left: 0;
  padding-right: 0;
  box-shadow: 0 1px 0 0 var(--color-border-tertiary);
  border-radius: 0px;
  width: 100%;
  display: block;
}

.mobile .m-header-popover li:last-child a,
.mobile [data-item='menu'] li:last-child a {
  box-shadow: 0 1px 0 0 transparent;
}


.m-header nav a[data-trigger='toggleMenu'] {
  display: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }
}

a {
  text-decoration: none;
}

.m-markdown * {
  font-family: InterMedium;
}

.m-markdown h1,
.m-markdown h2 {
  font-family: InterBold;
}

.m-markdown *:first-of-type {
  margin-top: 0;
}


/* Desktop */
@media (--device-desktop) {
}

/* Laptop */
@media (--device-laptop) {
  .m-horizontaltextboxes li.g-cell.-bordered .image img.-XL {
    width: 90px;
  }

  #proyectos>.g-container::before {
    transform: translateY(-460px);
    font-size: 142px;
  }

  #contacto>.g-container::before {
    width: 154px;
    height: 154px;
    top: -48px;
    left: 8px;
  }

  #compromiso>.g-container::before {
    width: 154px;
    height: 154px;
  }

}

/* Tablet */
@media (--device-tablet) {
  #contacto>.g-container::before,
  #compromiso>.g-container::before {
    display: none;
  }  
  
  .m-footer a:hover::before {
    opacity: 0;
  }
  
  .m-header a:hover::before {
    opacity: 0;
  }

  .m-header nav {
    display: flex;
    align-items: center;
  }

  .m-header .logo a {
    vertical-align: text-top;
  }

  .m-header nav a[data-trigger='toggleMenu'] {
    color: var(--color-bodytext-primary-action);
    display: block;
    padding: 0;
  }

  .m-header a {
    margin-right: 0;
  }

  .m-header a:hover::before {
    display: none;
  }

  .m-header .g-cell.-x12 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .m-header #-menu-mobile {
    display: inherit;
    opacity: 1;
    position: absolute;
    right: 0;
  }

  .m-header #-menu-mobile i {
    background-color: var(--color-bodytext-primary-action);
    margin: 0;
  }
  
  .m-header nav.g-cell.-x9 {
    width: 100%;
  }

  .m-header>.g-container {
    width: var(--grid-container);
    position: relative;
  }

  .m-header nav {
    flex-grow: 0;
    display: inline-flex;
  }

  .m-header nav ul li a {
    color: var(--color-background-base);
  }

  .m-header nav ul,
  .m-footer ul {
    display: flex;
    flex-direction: column;
    padding-top: var(--spacing-medium);
    padding-bottom: var(--spacing-medium);
    width: 100%;
  }

  .m-footer > .g-container {
    justify-content: center;
  }
  .m-footer img {
    margin: auto;
  }

  .m-footer ul li a {
    margin-right: 0;
    padding-top: 0;
    padding-bottom: var(--spacing-xxsmall);
    text-align: center;
  }

  .m-header nav ul li a {
    margin-right: 0;
    text-align: center;
  }

  .m-header nav ul li a:hover {
    background-image: none !important;
    color: #63FB4C;
  }


}

/* Mobile */
@media (--device-mobile) {
  .m-header .logo img,
  .m-footer .logo img {
    height: 24px;
    width: auto;
    display: block;
  }

  .m-heroillustration header {
    margin-bottom: var(--spacing-xlarge);
  }

  .m-horizontaltextboxes ul li.g-cell.-bordered {
    padding: var(--spacing-large);
  }

  .m-mediaimage>.g-container .g-cell.-x6 {
    margin-bottom: var(--spacing-xxsmall);
  }

  .m-mediaimage .t-text-lead+* {
    margin-top: var(--spacing-xxsmall);
  }

  #proyectos>.g-container::before {
    font-size: 64px;
    line-height: .80;
    transform: translateY(-200px);
  }

  #contacto>.g-container::before,
  #compromiso>.g-container::before {
    display: none;
  }

  .e-tag {
    font: 12px InterBold;
}

  .m-jumbo > section {
    padding: var(--spacing-large) var(--spacing-large);
  }

  .m-horizontaltextboxes.-group + .m-horizontaltextboxes.-group {
    padding-bottom: var(--spacing-xxlarge);
  }

  .m-heroillustration .t-subtitle {
    font: var(--typography-text-superlead) InterMedium;
}

  .m-horizontaltextboxes li.g-cell.-bordered .image img.-XL {
    width: 80px;
  }

  .m-heroillustration .t-title-1 {
    font: 600 var(--typography-title-1) PermanentMarker;
  }

  .m-heroillustration + .m-imagesimple .e-image.-ratio-16-9 {
    aspect-ratio: 16/9;
  }
}